import { CardWrapper } from 'layout/PublicLayout/Wrappers';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { Stack, Grid, TablePagination, CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { athlete, club } from 'apis';
import { Autocomplete, TextInput } from 'qubCommon/Inputs';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import React, { useState } from 'react';
import { debounce } from 'lodash';

const View = () => {
    const form = useForm();
    const organization = useWatch({
        control: form.control,
        name: 'Organization'
    });
    const [autocompleteValue, setAutoCompleteValue] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [totalRows, setTotalRows] = useState<number | null>(null);
    const { t } = useTranslation();

    const handleChangePage = (_: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const athletesQuery = useQuery({
        queryKey: ['athletes', organization, search, page, rowsPerPage],
        queryFn: async () => {
            var res = await athlete.getPublicAthletes({
                organizationId: organization?.id,
                search: search,
                Page: page + 1,
                PageSize: rowsPerPage
            });
            setTotalRows(res.count);
            return res;
        }
    });

    const organizationsQuery = useQuery({
        queryKey: ['organizations', autocompleteValue],
        queryFn: async () => {
            var res = await club.getClubsPublic({
                search: autocompleteValue
            });
            return res;
        }
    });

    const handleAutoCompleteChange = debounce(function (value: string) {
        setAutoCompleteValue(value);
    }, 500);

    const handleSearchChange = debounce(function (value: string) {
        setSearch(value);
    }, 500);

    return (
        <CardWrapper>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <FormProvider {...form}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Autocomplete
                                label={t('Organization')}
                                name="Organization"
                                labelName="title"
                                options={organizationsQuery.data || []}
                                onInputChange={(_, value) => handleAutoCompleteChange(value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                label={t('Search')}
                                name="Search"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)}
                                endAdornment={athletesQuery?.isLoading ? <CircularProgress size="18px" /> : null}
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
                <FlexGrid itemsSource={athletesQuery?.data?.items || []} className="public-grid" isReadOnly headersVisibility={1}>
                    <FlexGridColumn header={t('RegNumber')} binding="regNumber" width="1*" minWidth={100} />
                    <FlexGridColumn header={t('Name')} binding="fullName" width="2*" minWidth={200} />
                    <FlexGridColumn header={t('Gender')} binding="gender" width="1*" minWidth={100} />
                    <FlexGridColumn header={t('Yob')} binding="dob" width="1*" format="d" minWidth={100} />
                    <FlexGridColumn header={t('Organization')} binding="organization" width="3*" minWidth={200} />
                    <FlexGridColumn header={t('Nationality')} binding="nationality" width="*" minWidth={100} />
                </FlexGrid>
                {totalRows && (
                    <TablePagination
                        component="div"
                        count={totalRows}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Stack>
        </CardWrapper>
    );
};

export default View;
