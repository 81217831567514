import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from 'qubCommon/Other/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import PublicLayout from 'layout/PublicLayout';
import { useSettings } from 'hooks/useSettings';
import { PublicClubView } from 'views/public/Clubs';
import { PublicAthletesView } from 'views/public/Athletes';
import { PublicOffialsView } from 'views/public/Officials';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/authentication/authentication3/Register3')));
const ResetPassword = Loadable(lazy(() => import('views/authentication/forgotPassword')));
const UserInvitation = Loadable(lazy(() => import('views/authentication/userInvitation')));
const PaymentSubmittion = Loadable(lazy(() => import('views/application/Payments/Submittion')));
const TermsOfUse = Loadable(lazy(() => import('views/TermsOfUse')));
const PaymentResultPage = Loadable(lazy(() => import('views/application/Payments/Submittion/paymentMethod/byCard/form/resultPage')));
const CompetitionsCalendar = Loadable(lazy(() => import('views/public/Competitions').then((module) => ({ default: module.Calendar }))));
const CompetitionDetails = Loadable(lazy(() => import('views/public/Competitions').then((module) => ({ default: module.Details }))));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = () => {
    // eslint-disable-next-line camelcase
    const { project_public_enabled } = useSettings();

    return [
        {
            path: '/',
            element: <MinimalLayout />,
            children: [
                {
                    path: '',
                    element: <Navigate to="/login" />
                },
                {
                    path: 'api/payment/success',
                    element: <PaymentResultPage />
                },
                {
                    path: 'api/payment/fail',
                    element: <PaymentResultPage />
                },
                {
                    path: '/login',
                    element: <AuthLogin3 />
                },
                /* {
                path: '/register',
                element: <AuthRegister3 />
            }, */
                {
                    path: '/reset/:token',
                    element: <ResetPassword />
                },
                {
                    path: '/invite',
                    element: <UserInvitation />
                },
                {
                    path: '/invite',
                    element: <UserInvitation />
                },
                {
                    path: 'TermsOfUse',
                    element: <TermsOfUse />
                },
                {
                    path: '/payment/:token',
                    element: <PaymentSubmittion />
                }
            ]
        },
        {
            path: 'public',
            // eslint-disable-next-line camelcase
            element: project_public_enabled === 'true' ? <PublicLayout /> : <Navigate to="/login" />,
            children: [
                {
                    path: '',
                    element: <Navigate to="calendar" />
                },
                {
                    path: 'calendar',
                    element: <CompetitionsCalendar />
                },
                {
                    path: 'competition',
                    element: <CompetitionDetails />
                },
                {
                    path: 'athletes',
                    element: <PublicAthletesView />
                },
                {
                    path: 'officials',
                    element: <PublicOffialsView />
                },
                {
                    path: 'clubs',
                    element: <PublicClubView />
                }
            ]
        },
        {
            path: '*',
            element: <Navigate to={`/login?navUrl=${window.location.pathname}${window.location.search}`} />
        }
    ];
};
export default AuthenticationRoutes;
