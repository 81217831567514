import { ToolBarButtonProps } from '../types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { MenuItem as MuiMenuItem } from '@mui/material';

const MenuItem = (props: ToolBarButtonProps) => {
    const { url, label, isExternal, onClick } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavMenu = () => {
        if (url) {
            if (onClick) onClick();
            if (isExternal) {
                window.open(url, '_blank');
                window.focus();
            } else {
                navigate(url);
            }
        }
    };

    const isActiveTab = useMemo(() => {
        return location.pathname === url;
    }, [url, location]);

    return (
        <MuiMenuItem
            onClick={handleNavMenu}
            sx={{
                background: isActiveTab ? '#7eaaca' : '#f8f9fa',
                ':hover': { background: isActiveTab ? '#62a8db' : '#dbdbdb' },
                color: isActiveTab ? '#fff' : 'inherit'
            }}
        >
            {label}
        </MuiMenuItem>
    );
};

export default MenuItem;
