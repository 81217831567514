import { useMemo, useState } from 'react';
import './Map.css';
import { useQuery } from 'react-query';
import { club } from 'apis';
import { Tooltip, Typography, Fade, Grid, Stack, Divider, Button } from '@mui/material';
import { Circle, ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, PieChart, Pie, CartesianGrid, XAxis, ResponsiveContainer, Sector, Cell, Legend } from 'recharts';
import { minHeight } from '@mui/system';

interface Region {
    id: number;
    name: string;
    pathCoordinates: string;
    athleteCount: number;
    clubCount: number;
    normalizedAthleteCount?: number | null;
    normalizedClubCount?: number | null;
    athletePercentage?: number | null;
    clubPercentage?: number | null;
}

interface MapProps {
    onSelectRegion: (id: number) => void;
}

const renderActiveShape = (props, label: string) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            {`${label} ${payload.name}`.split(' ').map((word: string, index: number) => (
                <text key={index} x={cx} y={cy - payload.name.split(' ').length * 10 + index * 20} dy={8} textAnchor="middle" fill={fill}>
                    {word}
                </text>
            ))}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
                {value}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const Map = (props: MapProps) => {
    const { onSelectRegion } = props;
    const { t } = useTranslation();
    const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
    const [activeItemId, setActiveItemId] = useState<number>(0);

    const handleMouseEnter = (index: number) => {
        setActiveItemId(index);
    };

    const geolocationQuery = useQuery(
        'geolocation',
        async () => {
            const res = await club.getGeolocationClubs();
            const minAthleteCount = Math.min(...res.map((region: Region) => region.athleteCount));
            const maxAthleteCount = Math.max(...res.map((region: Region) => region.athleteCount));
            const totalAthleteCount = res.reduce((acc: number, region: Region) => acc + region.athleteCount, 0);
            const minClubCount = Math.min(...res.map((region: Region) => region.clubCount));
            const maxClubCount = Math.max(...res.map((region: Region) => region.clubCount));
            const totalClubCount = res.reduce((acc: number, region: Region) => acc + region.clubCount, 0);
            const result = res.map((region: Region) => {
                return {
                    ...region,
                    normalizedAthleteCount: (region.athleteCount - minAthleteCount) / (maxAthleteCount - minAthleteCount),
                    normalizedClubCount: (region.clubCount - minClubCount) / (maxClubCount - minClubCount),
                    athletePercentage: (region.athleteCount / totalAthleteCount) * 100,
                    clubPercentage: (region.clubCount / totalClubCount) * 100
                };
            });

            return result;
        },
        { suspense: true }
    );

    const activeIndex = useMemo<number>(
        () => geolocationQuery?.data?.findIndex((region: Region) => region.id === activeItemId),
        [activeItemId, geolocationQuery]
    );

    const handleSelectRegion = (id: number) => {
        setSelectedRegion(geolocationQuery?.data?.find((region: Region) => region?.id === id));
    };

    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Grid item container xs={12}>
                <Grid item xs={6}>
                    <div className="map-container">
                        <svg viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid meet">
                            <g>
                                {geolocationQuery?.data?.map((region: Region) => (
                                    <Tooltip
                                        key={region?.id}
                                        followCursor
                                        TransitionComponent={Fade}
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, 14]
                                                        }
                                                    }
                                                ]
                                            }
                                        }}
                                        title={
                                            <>
                                                <Typography>{region?.name}</Typography>
                                            </>
                                        }
                                    >
                                        <path
                                            onClick={() => handleSelectRegion(region.id)}
                                            onMouseEnter={() => handleMouseEnter(region.id)}
                                            className={`region ${activeItemId === region.id ? 'active' : ''}`}
                                            d={region?.pathCoordinates}
                                            fill={`rgba(53, 133, 157, ${region.normalizedClubCount})`}
                                            stroke="#7eaaca"
                                            strokeWidth="1.5"
                                        />
                                    </Tooltip>
                                ))}
                                {/* <circle r="8" cx="379.8" cy="237.6" fill="red" /> */}
                            </g>
                        </svg>
                    </div>
                </Grid>
                <Grid item container xs={6}>
                    <Grid item container xs={12}>
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    dataKey="clubCount"
                                    fill="#7eaaca"
                                    activeIndex={activeIndex}
                                    data={geolocationQuery?.data}
                                    outerRadius={100}
                                    innerRadius={70}
                                    minAngle={10}
                                    cx="50%"
                                    cy="25%"
                                    activeShape={(props) => renderActiveShape(props, 'Clubs')}
                                    onMouseEnter={(item, _) => handleMouseEnter(item.id)}
                                    style={{ outline: 'none' }}
                                    // labelLine={false}
                                />
                                <Pie
                                    dataKey="athleteCount"
                                    fill="#7eaaca"
                                    activeIndex={activeIndex}
                                    data={geolocationQuery?.data}
                                    cx="50%"
                                    cy="75%"
                                    outerRadius={100}
                                    innerRadius={70}
                                    minAngle={10}
                                    activeShape={(props) => renderActiveShape(props, 'Athletes')}
                                    onMouseEnter={(item, _) => handleMouseEnter(item.id)}
                                    style={{ outline: 'none' }}
                                    labelLine={false}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Map;
