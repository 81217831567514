import { PageWrapper } from 'layout/PublicLayout/Wrappers';
import View from './View';
import { Suspense } from 'react';

export const PublicOffialsView = () => {
    return (
        <PageWrapper>
            <Suspense fallback={<div>Loading...</div>}>
                <View />
            </Suspense>
        </PageWrapper>
    );
};
