import { Autocomplete as MuiAutocomplete, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';
import { CssTextField } from './CssTextField';
import ColumnLabel from './ColumnLabel';

interface AutocompleteProps {
    name: string;
    label?: string;
    options?: any[];
    noOptionsText?: string;
    onInputChange?: (e: React.SyntheticEvent, value: string) => void;
    labelName?: string;
}

const Autocomplete = (props: AutocompleteProps) => {
    const { name, label, options, onInputChange, noOptionsText, labelName } = props;
    const { field } = useController({ name: name });

    return (
        <MuiAutocomplete
            options={options || []}
            autoComplete
            autoHighlight
            getOptionLabel={(option) => (labelName ? option[labelName] : option.label)}
            value={field.value || null}
            onChange={(_, value) => {
                field.onChange(value);
            }}
            filterOptions={(x) => x}
            noOptionsText={noOptionsText}
            onInputChange={onInputChange}
            renderInput={(params) => {
                const customParams = { ...params, InputProps: { ...params?.InputProps, disableUnderline: true }, fixedPadding: 7.5 };
                return (
                    <Stack sx={{ marginTop: 0.1 }}>
                        <ColumnLabel label={label} />
                        <CssTextField variant="standard" {...customParams} fullWidth size="small" />
                    </Stack>
                );
            }}
        />
    );
};

export default Autocomplete;
