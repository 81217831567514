import { Box, Typography, Stack, Skeleton } from '@mui/material';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { useTranslation } from 'react-i18next';
import 'views/public/assets/public-grid.css';
import { useQuery } from 'react-query';
import { apiEntities, club } from 'apis';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { format } from 'date-fns';

const ClubsGrid = (props) => {
    const { data = [] } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const dateCellTemplate = useCallback(
        (ctxDate) => {
            let date;
            try {
                date = format(new Date(ctxDate), 'dd/MM/yyyy');
            } catch (e) {
                date = null;
            }
            return <div style={{ textAlign: 'center' }}>{date}</div>;
        },
        [data]
    );

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Stack direction="row" spacing={1} justifyContent="fflex-start" alignItems="center">
                <Box sx={{ width: '5px', height: '100%', background: '#4d79bc', borderRadius: 2 }} />
                <Typography sx={{ fontSize: '30px', fontFamily: 'Inter' }}>{t('Calendar')}</Typography>
            </Stack>
            <FlexGrid itemsSource={data} selectionMode={0} autoRowHeights isReadOnly headersVisibility={1} className="public-grid">
                <FlexGridColumn header={t('Code')} binding="code" width="*" align="center" allowSorting={0} />
                <FlexGridColumn header={t('Title')} binding="title" width="2*" />
                <FlexGridColumn header={t('Abbr')} binding="abbreviation" width="*" wordWrap />
                <FlexGridColumn header={t('FoundedAt')} binding="founded" width="*" align="center">
                    <FlexGridCellTemplate cellType="Cell" template={(ctx) => dateCellTemplate(ctx.item?.founded)} />
                </FlexGridColumn>
                <FlexGridColumn header={t('Email')} binding="email" width="1.5*" />
                <FlexGridColumn header={t('Url')} binding="url" width="2*" wordWrap />
            </FlexGrid>
        </Stack>
    );
};

export default ClubsGrid;
