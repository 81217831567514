import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { ToolBarButtonProps } from '../types';

const ToolBarButton = (props: ToolBarButtonProps) => {
    const { url, label, isExternal } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavMenu = () => {
        if (url) {
            if (isExternal) {
                window.open(url, '_blank');
                window.focus();
            } else {
                navigate(url);
            }
        }
    };

    const isActiveTab = useMemo(() => {
        return location.pathname === url;
    }, [url, location]);

    return (
        <Button
            variant="text"
            color="inherit"
            onClick={handleNavMenu}
            sx={{
                background: isActiveTab ? '#7eaaca' : '#f8f9fa',
                ':hover': { background: isActiveTab ? '#62a8db' : '#dbdbdb' },
                color: isActiveTab ? '#fff' : 'inherit'
            }}
        >
            {label}
        </Button>
    );
};

export default ToolBarButton;
