import axiosInstance from '../axiosInstance';

export const officialApi = {
    post: (path, params, cancelToken) =>
        axiosInstance
            .post(`/official${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/official${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const update = async (params) => officialApi.post('/save', params);

export const getRequiredAttachments = async (params) => officialApi.get('/requiredattachmentsbyfunction', { params });

export const deleteAthlete = async (params) => officialApi.post('/deleteAthlete', params);

export const getPublicOfficials = async (params) => officialApi.get('/getPublicOfficials', { params });
