import { Box } from '@mui/material';

const CardWrapper = (props) => {
    const { children } = props;

    return (
        <Box sx={{ display: 'flex', width: '80%', borderRadius: 2, background: '#F8F8F8', boxShadow: 1, py: 2, px: 5, marginTop: '-15px' }}>
            {children}
        </Box>
    );
};

export default CardWrapper;
