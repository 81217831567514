import axiosInstance from '../axiosInstance';

export const athleteApi = {
    post: (path, params, cancelToken) =>
        axiosInstance
            .post(`/athlete${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/athlete${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const update = async (params) => athleteApi.post('/save', params);

export const getRequiredAttachments = async (params) => athleteApi.get('/requiredattachmentsbyfunction', { params });

export const deleteAthlete = async (params) => athleteApi.post('/deleteAthlete', params);

export const getPublicAthletes = async (params) => athleteApi.get('/getPublicAthletes', { params });
