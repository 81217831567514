import {
    Box,
    Grid,
    Button,
    Toolbar,
    useMediaQuery,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Divider,
    Typography,
    MenuList
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import { Logo } from 'ui-component';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import ToolBarButton from './ToolBarButton';
import { ToolBarProps } from '../types';
import MenuItem from './MenuItem';

const ToolBar = (props: ToolBarProps) => {
    const { open, projectLogo, federationUrl, buttons = [] } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [openAccordion, setOpenAccordion] = useState<boolean>(false);

    const menuSettings = {
        disableCalendar: false,
        disableAthletes: false,
        disableOfficials: false,
        disableClubs: false
    };

    const height = useMemo(() => {
        if (lgUp) {
            return open ? 45 : 55;
        }
        return 'auto';
    }, [lgUp, open]);

    const handleNavMenu = (url: string) => {
        navigate(url);
    };

    const handleNavFederation = () => {
        window.open(federationUrl, '_blank');
        window.focus();
    };

    return (
        <Box
            sx={{
                height: height, // open ? 45 : 55,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                overflow: 'hidden',
                background: '#f8f9fa'
            }}
        >
            <Grid container xs={12} alignItems="center" sx={{ overflowX: 'hidden', overflowY: lgUp ? 'hidden' : 'visible' }}>
                <Grid
                    item
                    container
                    xs={1}
                    justifyContent="center"
                    sx={{
                        opacity: open ? 0 : 1,
                        transition: 'opacity 0.3s ease',
                        overflow: 'hiddden',
                        ml: lgUp ? 0 : 1
                    }}
                >
                    <Logo logo={projectLogo} dimensions={{ width: '5vh', height: '5vh' }} />
                </Grid>
                <Grid item xs={9}>
                    {lgUp ? (
                        <Toolbar>
                            {buttons.map((button) => button?.enabled && <ToolBarButton {...button} label={t(button.label)} />)}
                        </Toolbar>
                    ) : (
                        <>
                            <Accordion
                                expanded={openAccordion}
                                onChange={() => setOpenAccordion((prev) => !prev)}
                                sx={{ width: '100%', mt: 1, background: '#f8f9fa' }}
                            >
                                <AccordionSummary
                                    expandIcon={<Menu />}
                                    sx={{
                                        flexDirection: 'row-reverse',
                                        borderTop: 'none',
                                        '& .MuiAccordionSummary-content': {
                                            marginLeft: 1
                                        },
                                        '& .MuiAccordionSummary-content.Mui-expanded': {
                                            marginLeft: 1
                                        },
                                        background: '#f8f9fa'
                                    }}
                                >
                                    <Typography fontSize="medium" fontWeight={400}>
                                        Menu
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MenuList>
                                        {buttons.map((button) => (
                                            <MenuItem {...button} label={t(button.label)} onClick={() => setOpenAccordion(false)} />
                                        ))}
                                        <Divider sx={{ borderColor: '#727273' }} />
                                        <MenuItem url="/login" label={t('MemberLogin')} onClick={() => setOpenAccordion(false)} />
                                    </MenuList>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </Grid>
                {lgUp && (
                    <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
                        <Button variant="text" sx={{ color: '#7eaaca' }} onClick={() => handleNavMenu('/login')}>
                            <u>{t('MemberLogin')}</u>
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ToolBar;
